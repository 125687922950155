/* App.css */

* {
  box-sizing: border-box;
  color: #fff;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2e2d2f;
}

header, h1{
  font-weight: lighter;
}

.bg {
  background: url('./assets/bg.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.container {
  max-width: 800px;
}

.small-logo {
  max-height: 150px; 
  filter: drop-shadow(0px 0px 10px #fff);
  background-color: #fff;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 35%);
}

.display-4 {
  font-weight: 300;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
  font-weight: bold;
}

.header {
  width: 100%;
  background: linear-gradient(to bottom, rgba(46, 45, 47, 1) 0%, rgba(46, 45, 47, 0.3) 70%, rgba(46, 45, 47, 0) 100%);
  z-index: 10; /* making sure the header is drawn on top of everything else */
  position: relative; /* making the z-index effective */
}

a{
}
